import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import en from './locales/en';
import enGb from './locales/en_gb';
import no from './locales/no';
import swe from './locales/swe';
import danish from './locales/danish';
import finnish from './locales/finnish';
import ge from './locales/german';
import is from './locales/icelandic';
import es from './locales/spanish';
import pt from './locales/portuguese';
import br from './locales/brazilian';
import { createI18n } from "vue-i18n";
import Vue3TouchEvents from "vue3-touch-events";

const i18n = createI18n({
  messages: {
    en,
    enGb,
    no,
    swe,
    danish,
    finnish,
    ge,
    is,
    es,
    pt,
    br
  },
  legacy: false,
  fallbackLocale: 'en'
});

createApp(App).use(i18n).use(Vue3TouchEvents).use(store).use(router).mount('#app');
