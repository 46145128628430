<template>
  <router-view v-if="showContent" />
</template>

<script>
  import { mapState, mapMutations, mapActions } from 'vuex';
  import { request } from '@/api/APIService';

  export default {
    data(){
      return{
        showContent: false
      }
    },
    async created(){
      const currentUrl = window.location;
      const allRoutes = this.$router.getRoutes();
      const correctRoute = allRoutes.some( route => route.path === currentUrl.pathname);

      if(!correctRoute) {
        await this.$router.push({ name: 'Error' });
        this.showContent = true;
        return
      }

      if(currentUrl.pathname === '/logged-out') {
        const url = new URL(currentUrl);
        const key = url.searchParams.get("key");
        const lang = +url.searchParams.get("lang");
        const presentationId = url.searchParams.get("presentationId");

        this.setLang(lang);
        this.setLanguageGlobal(lang);

        await this.$router.push({ name: 'LoggedOut', query: {key, presentationId, lang} });
        this.showContent = true;
        return;
      }

      this.addKey();

      const data = {
        key: this.key,
        scope: 'fetchOverview'
      };

      try {
        let res = await request(data);

        if (res.data.data.presentation && res.data.data.presentation.languageId){
          const langId = res.data.data.presentation.languageId;

          this.setLang(langId);
          this.setLanguageGlobal(langId);
        }

        if(res.data.errors.length !== 0 && res.data.errors[0].code === 59){
          if(res.data.data.presentation && res.data.data.presentation.id){
            this.setPresentationId(res.data.data.presentation.id);
          }
          await this.$router.push({ name: 'PresentationClosed', query: {key: this.key} });
          this.showContent = true;
          return;
        }

        if(res.data.errors.length !== 0 &&  res.data.errors[0].code === 25){
          if(res.data.data.presentation && res.data.data.presentation.id){
            this.setPresentationId(res.data.data.presentation.id);
          }
          await this.$router.push({ name: 'Error'});
          this.showContent = true;
          return;
        }

        if(res.data.data.authMethod === 1){
          this.setPresentationId(res.data.data.presentation.id);
          await this.$router.push({ name: 'Recaptcha', query: {key: this.key} });
          this.showContent = true;
          return;
        }

        if(res.data.data.authMethod === 2){
          this.setSsoIds(res.data.data.ssoIds);
          this.setPresentationId(res.data.data.presentation.id);
          await this.$router.push({ name: 'Auth', query: {key: this.key} });
          this.showContent = true;
          return;
        }

        if(res.data.success) {
          this.mapOtherReceiversReviewToCandidates(res.data.data.candidates, res.data.data.otherReceivers);
          this.setInitialData(res.data.data);
          this.setIsSingleCandidate(res.data.data.candidates.length);
          this.setPresentationData(res.data.data.presentation);
          this.setReviewAccess(res.data.data.presentation.reviewAccess);
          this.setAuth(res.data.data.auth.length > 0);
          this.setPresentationId(res.data.data.presentation.id);

          if(res.data.data.auth.length && (res.data.data.auth[0].auth && res.data.data.auth[0].auth.method !== 'sms')){
            this.setReceiver(res.data.data.auth[0].auth);
          }
          else{
            this.setReceiver(res.data.data.receiver);
          }

          document.title = res.data.data.presentation.title;
          // this.candidatesSort();
          this.showContent = true;
        }
        if(!res.data.success && res.data.errors[0].code === 26){
          await this.$router.push({ name: 'Error'});
          this.showContent = true;
          return;
        }
      }
      catch (e) {
        console.dir(e);
      }

      if(this.singleCandidate){
        this.$router.push({ name: 'CandidateSingle', replace: true, query: {
            presentationId: this.initialData.candidates[0].presentationId,
            candidateId: this.initialData.candidates[0].candidateId,
            key: this.key
          }});
      }
    },
    computed: {
      ...mapState([
        'initialData',
        'key',
        'singleCandidate'
      ]),
    },
    methods: {
      ...mapMutations([
        'setInitialData',
        'setIsSingleCandidate',
        'setPresentationData',
        'setReceiver',
        'setLang',
        'setSsoIds',
        'setPresentationId',
        'setReviewAccess',
        'setAuth'
      ]),
      ...mapActions([
        'addKey',
        // 'candidatesSort'
      ]),
      mapOtherReceiversReviewToCandidates(candidates, otherReceivers) {
        otherReceivers.forEach(receiver => {
          if(receiver.review.length > 0) {
            receiver.review.forEach(review => {
              candidates.some(candidate => {
                if(candidate.candidateId === review.candidateId){
                  candidate.reviews.push({comment: review.comment, created: review.created, rating: review.rating, name: receiver.name, fromReceiver: true})
                }
              });
            })
          }
        });
      },
      setLanguageGlobal(langId) {
        const html = document.documentElement;

        switch (langId) {   // https://www.w3schools.com/tags/ref_language_codes.asp
          case 1:
            this.$i18n.locale = 'en';
            html.setAttribute('lang', 'en');
            break;
          case 2:
            this.$i18n.locale = 'swe';
            html.setAttribute('lang', 'sv');
            break;
          case 3:
            this.$i18n.locale = 'danish';
            html.setAttribute('lang', 'da');
            break;
          case 4:
            this.$i18n.locale = 'no';
            html.setAttribute('lang', 'no');
            break;
          case 6:
            this.$i18n.locale = 'ge';
            html.setAttribute('lang', 'de');
            break;
          case 7:
            this.$i18n.locale = 'es';
            html.setAttribute('lang', 'es');
            break;
          case 10:
            this.$i18n.locale = 'finnish';
            html.setAttribute('lang', 'fi');
            break;
          case 11:
            this.$i18n.locale = 'is';
            html.setAttribute('lang', 'is');
            break;
          case 24:
            this.$i18n.locale = 'pt';
            html.setAttribute('lang', 'pt');
            break;
          case 25:
            this.$i18n.locale = 'br';
            html.setAttribute('lang', 'br');
            break;
          case 26:
            this.$i18n.locale = 'enGb';
            html.setAttribute('lang', 'en');
            break;
          default:
            this.$i18n.locale = 'en';
            html.setAttribute('lang', 'en');
        }
      }
    }
  }
</script>

<style lang="scss">
  @import '~normalize.css';

  html,
  body{
    height: 100%;
    overflow: auto;

    @media print{
      overflow: initial;
    }
  }

  body {
    font-family: "Open Sans", Times, "Times New Roman", serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  #app{
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }

  .btn{
    border: 0;
    color: #fff;
    border-radius: 3px;
    background: transparent;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    min-width: 136px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 9px 15px;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }

  .btn--green {
    background-color: #60b346;
  }

  .btn--green:hover {
    background-color: #437c30;
  }

  input:focus{
    border-color: #999;
    outline: none;
  }

  .validator{
    color: red;
    font-size: 12px;
    margin-top: 7px;
    display: inline-block;
  }

  .error-panel{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: rgba(237, 40, 40, 0.05);
    border-left: 4px solid #ED2828;
    justify-content: center;
    padding: 18px 0 20px 20px;
    margin-top: 30px;
    margin-right: 20px;
    max-width: 640px;
    box-sizing: border-box;
    width: 90%;

    svg{
      width: 14px;
      padding-right: 14px;
    }
  }

  .error-panel__wrap{
    display: flex;
    align-items: center;

    &.error-panel__wrap-submit{
      flex-direction: column;
    }

    div{
      margin-bottom: 5px;

      &:last-child{
        margin-bottom: 0;
      }
    }
  }

  .error-panel__text{
    font-size: 12px;
    color: #742C25;
  }

  .progress-line{
    .progress::-webkit-progress-bar {background-color: #2E2D2D; width: 100%;}
    .progress {background-color: #2E2D2D;}
    .progress::-webkit-progress-value {background-color: #0075FF !important;}
    .progress::-moz-progress-bar {background-color: #0075FF !important;}
    .progress {color: #0075FF;}
  }


</style>
