<template>
  <div class="overview__wrap" v-if="initialData">
    <PreviewMode v-if="!reviewAccess"/>
    <Header :candidatePage="false" :candidatePageMulti="false" :logo="initialData.corporation.logo" />
    <Overview />
    <Receivers v-if="initialData.otherReceivers.length > 0" :receivers="initialData.otherReceivers" />
    <div v-else class="presentation__id">
      Presentation ID {{ presentationId }}
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import Header from '@/components/Header';
  import Overview from "./Overview";
  import PreviewMode from "../components/PreviewMode"
  import { request } from '@/api/APIService';
  import Receivers from "../components/Receivers";


export default {
  name: 'OverviewWrap',
  components: {
    Header,
    Overview,
    PreviewMode,
    Receivers
  },
  data(){
    return{
      showContent: false
    }
  },
  computed: {
    ...mapState([
      'initialData',
      'key',
      'singleCandidate',
      'reviewAccess',
      'presentationId'
    ]),
  },
  async created(){
    if(!this.initialData){    // if we come from reCaptcha or any Auth pages - there is no data at all and we fetch it
      const data = {
        key: this.key,
        scope: 'fetchOverview'
      };

      try {
        let res = await request(data);

        if (res.data.success && res.data.data.authMethod !== 1 && res.data.data.authMethod !== 2) {
          this.mapOtherReceiversReviewToCandidates(res.data.data.candidates, res.data.data.otherReceivers);
          this.setInitialData(res.data.data);
          this.setIsSingleCandidate(res.data.data.candidates.length);
          this.setPresentationData(res.data.data.presentation);
          this.setReviewAccess(res.data.data.presentation.reviewAccess);
          this.setAuth(res.data.data.auth.length > 0);
          this.setPresentationId(res.data.data.presentation.id);

          if(res.data.data.auth.length && (res.data.data.auth[0].auth && res.data.data.auth[0].auth.method !== 'sms')){
            this.setReceiver(res.data.data.auth[0].auth);
          }
          else{
            this.setReceiver(res.data.data.receiver);
          }

          document.title = res.data.data.presentation.title;
          // this.candidatesSort();
          this.showContent = true;
        }
        else if(res.data.errors) {
          if (res.data.errors[0].code === 59){
            this.showContent = true;
            this.$router.push({ name: 'PresentationClosed', query: {key: this.key} });
          }
          else if(res.data.errors[0].code === 25){
            this.showContent = true;
            this.$router.push({ name: 'Error'});
          }
        }
      }
      catch (e) {
        console.dir(e);
      }

      if(this.singleCandidate){
        this.$router.push({ name: 'CandidateSingle', replace: true, query: {
            presentationId: this.initialData.candidates[0].presentationId,
            candidateId: this.initialData.candidates[0].candidateId,
            key: this.key
          }});
      }
    }
  },
  methods: {
    ...mapMutations([
      'setInitialData',
      'setIsSingleCandidate',
      'setPresentationData',
      'setReceiver',
      'setReviewAccess',
      'setAuth',
      'setPresentationId'
    ]),
    mapOtherReceiversReviewToCandidates(candidates, otherReceivers) {
      otherReceivers.forEach(receiver => {
        if(receiver.review.length > 0) {
          receiver.review.forEach(review => {
            candidates.some(candidate => {
              if(candidate.candidateId === review.candidateId){
                candidate.reviews.push({comment: review.comment, created: review.created, rating: review.rating, name: receiver.name, fromReceiver: true})
              }
            });
          })
        }
      });
    },
    // ...mapActions([
    //   'candidatesSort'
    // ])
  }
}
</script>

<style lang="scss" scoped>
  .overview__wrap{
    min-height: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }

  .presentation__id{
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #999;
    bottom: 40px;
    right: 40px;
    margin: 40px 20px;
    text-align: right;

    @media (min-width: 990px){
      position: absolute;
      margin: 0 20px;
    }
  }
</style>
