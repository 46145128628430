<template>
  <div class="candidate__item" :class="{'item__submitted' : feedbackAmount && !fromReceiver, 'item__sidebar' : isSidebar, 'selected' : isSelected, 'last' : lastCandidate}" @click="candidateClick">
    <div class="candidate__item_container">
      <div class="item__img">
        <svg v-if="!candidate.img" width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M28 0C12.5323 0 0 12.5323 0 28C0 43.4677 12.5323 56 28 56C43.4677 56 56 43.4677 56 28C56 12.5323 43.4677 0 28 0ZM28 10.8387C33.4871 10.8387 37.9355 15.2871 37.9355 20.7742C37.9355 26.2613 33.4871 30.7097 28 30.7097C22.5129 30.7097 18.0645 26.2613 18.0645 20.7742C18.0645 15.2871 22.5129 10.8387 28 10.8387ZM28 49.6774C21.3726 49.6774 15.4339 46.6742 11.4597 41.9774C13.5823 37.9806 17.7371 35.2258 22.5806 35.2258C22.8516 35.2258 23.1226 35.271 23.3823 35.35C24.85 35.8242 26.3855 36.129 28 36.129C29.6145 36.129 31.1613 35.8242 32.6177 35.35C32.8774 35.271 33.1484 35.2258 33.4194 35.2258C38.2629 35.2258 42.4177 37.9806 44.5403 41.9774C40.5661 46.6742 34.6274 49.6774 28 49.6774Z" fill="#EDECF2"/><path d="M37.9356 20.7739C37.9356 15.2868 33.4872 10.8384 28.0001 10.8384C22.513 10.8384 18.0646 15.2868 18.0646 20.7739C18.0646 26.261 22.513 30.7093 28.0001 30.7093C33.4872 30.7093 37.9356 26.261 37.9356 20.7739Z" fill="#C7C7CC"/><path d="M11.4594 41.9772C15.4336 46.674 21.3723 49.6772 27.9997 49.6772C34.6272 49.6772 40.5659 46.674 44.5401 41.9772C42.4175 37.9804 38.2626 35.2256 33.4191 35.2256C33.1481 35.2256 32.8772 35.2707 32.6175 35.3498C31.161 35.824 29.6143 36.1288 27.9997 36.1288C26.3852 36.1288 24.8497 35.824 23.382 35.3498C23.1223 35.2707 22.8514 35.2256 22.5804 35.2256C17.7368 35.2256 13.582 37.9804 11.4594 41.9772Z" fill="#C7C7CC"/><circle cx="28" cy="28" r="27.5" stroke="#BBBBBB"/></svg>
        <img v-else :src="candidate.img"  alt="Candidate Image">
      </div>
      <div class="item__info">
        <div class="item__info_left">
          <div class="item__name">
            <div v-if="candidate.name" v-html="candidate.name"></div>
            <div v-else>{{ t('Candidate ID') }} <span>{{candidate.candidateId}}</span></div>
          </div>
          <div class="item__descr" v-html="candidate.title"></div>
          <div class="item__stars" v-if="isSidebar && feedbackAmount">
            <div class="stars__container">
              <Stars :small="true" :mode="'read'" :rating="rating"/>
            </div>
            <div class="average__rating" v-if="feedbackAmount > 1">{{rating}}</div>
          <div class="feedback__amount" v-if="feedbackAmount > 0">
            <div>({{feedbackAmount}} {{ t('feedback') }})</div>
          </div>
          </div>
        </div>
        <div class="item__info_right" v-if="feedbackAmount">
          <div class="feedback__submitted_top" v-if="!fromReceiver">
            <div class="feedback__submitted">
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.3267 2.07985L3.99851 8.05648L1.67328 5.86045C1.56055 5.75398 1.37776 5.75398 1.265 5.86045L0.584551 6.50309C0.471816 6.60956 0.471816 6.78219 0.584551 6.88869L3.79435 9.92015C3.90709 10.0266 4.08988 10.0266 4.20264 9.92015L11.4154 3.10809C11.5282 3.00162 11.5282 2.82899 11.4154 2.72249L10.735 2.07985C10.6223 1.97338 10.4395 1.97338 10.3267 2.07985Z" fill="#60B346"/>
              </svg>
              {{ t('Feedback submitted') }}
            </div>
            <div class="submitted__date" >
              <Popover :popoverDisabled="popoverDisabled">
                <template v-slot:content>
                  <span >{{t(submittedDay)}}</span>
                </template>
                <template v-slot:popover>
                  {{popoverText}}
                </template>
              </Popover>
            </div>
          </div>
          <div class="feedback__submitted__bottom">
            <div class="stars__container">
              <Stars :small="true" :mode="'read'" :rating="rating"/>
            </div>
            <div class="average__rating" v-if="feedbackAmount > 1">{{rating}}</div>
            <div class="feedback__amount" v-if="feedbackAmount > 0">
              <div>({{feedbackAmount}} {{ t('feedback') }})</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import moment from 'moment';
  import Stars from "./Stars";
  import Popover from "../components/Popover";
  import { useI18n } from "vue-i18n";

  export default {
    props: ['candidate', 'isSidebar', 'index', 'isSelected', 'lastCandidate'],
    emits: ['candidate-selected'],
    components: {
      Stars,
      Popover
    },
    setup(){
      const { t } = useI18n();
      return { t };
    },
    data(){
      return{
        submittedDay: "",
        popoverText: "",
        popoverDisabled: false
      }
    },
    mounted(){
      if(this.candidate?.reviews?.length){
        const date = moment.utc(this.candidate.reviews[this.feedbackAmount - 1].created).local()
        const dateNow = moment();
        const dateYesterday = moment().add(-1,'days');

        if(date.isSame(dateNow, 'd')) {
          this.submittedDay = "Today";
          this.popoverText = date.format('HH:mm');
        }
        else if(date.isSame(dateYesterday, 'd')){
          this.submittedDay = "Yesterday";
          this.popoverText = date.format('DD.MM.YYYY HH:mm')
        }
        else{
          this.submittedDay = date.format('DD.MM.YYYY')
          this.popoverDisabled = true;
        }
      }
    },
    computed: {
      ...mapState([
        'initialData',
        'key',
        'lang'
      ]),
      feedbackAmount(){
        return this.candidate?.reviews?.length || 0;
      },
      fromReceiver() {
        if(this.candidate.reviews.length === 0) {
          return false;
        }

        return this.candidate.reviews.every(review => {
          return review.fromReceiver;
        })
      },
      feedbackDate(){
        return moment(this.candidate.reviews[this.feedbackAmount - 1].created).format('DD.MM.YYYY');
      },
      rating(){
        let rating = 0;
        this.candidate.reviews.forEach((item) => {
          rating += item.rating;
        });
        return (rating / this.feedbackAmount).toFixed(1);
      }
    },
    methods: {
      candidateClick(){
        this.$emit('candidate-selected', this.candidate.presentationId);
        this.$router.push({ name: 'Candidate', query: {
          presentationId: this.candidate.presentationId,
          candidateId: this.candidate.candidateId,
          key: this.key
        }});
      }
    }
  }
</script>

<style lang="scss" scoped>

  .candidate__item{
    padding: 10px 0 10px 20px;
    cursor: pointer;
    position: relative;

    &:before{
      content: '';
      position: absolute;
      bottom: 0;
      height: 1px;
      width: calc(100% - 96px);
      left: 96px;
      background-color: #E5E5EA;
    }

    &:last-child:before{
      content: none;
    }

    &.item__submitted{
      background-color: #F2FFF6;

      &:hover,
      &.selected{
        background-color: #E4F1E7;
      }
    }

    &.item__sidebar:before{
      content: none;
    }

    .item__img {
      max-width: 56px;
      width: 100%;
      height: 56px;
      align-self: flex-start;

      img, svg{
        width: 100%;
      }

      img{
        object-fit: cover;
      }

      img, svg{
        border-radius: 50%;
        height: 56px;
      }
    }

    &.item__sidebar{
      padding: 16px 0 16px 20px;
      box-sizing: border-box;

      .item__img{
        max-width: 40px;
        height: 40px;
      }

      img{
        object-fit: cover;
      }

      img, svg{
        border-radius: 50%;
        height: 40px;
      }

      .item__info{
        margin-left: 10px;
        height: initial;

        .item__info_right{
          display: none;
        }
      }

      .item__name{
        font-size: 13px;
        line-height: 18px;
        color: #333;

        span{
          text-transform: uppercase;
        }
      }

      .item__descr{
        font-size: 12px;
        line-height: 16px;
        color: #999;
      }
    }

    &:hover,
    &.selected{
      background-color: rgba(153, 153, 153, 0.1);
    }
  }

  .candidate__item_container{
    display: flex;
    align-items: center;
  }

  .item__info{
    margin-left: 20px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .item__name{
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #000;
    margin-bottom: 2px;
  }

  .item__descr{
    font-size: 13px;
    line-height: 18px;
    color: #999;
    padding-right: 10px;
  }

  .item__info_right{
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    margin-right: 20px;
    align-items: flex-start;
  }

  .feedback__submitted_top{
    display: flex;
    align-items: center;
    margin-left: auto;

    @media (min-width: 500px){
      margin-left: initial;
    }
  }

  .feedback__submitted{
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #60B346;
    align-items: center;
    display: none;

    @media (min-width: 500px){
      display: flex;
    }

    svg{
      margin-right: 5px;
    }
  }

  .submitted__date{
    font-weight: 600;
    font-size: 9px;
    line-height: 11px;
    color: #BBB;

    @media (min-width: 500px){
      margin-left: 20px;
    }
  }

  .feedback__submitted__bottom{
    display: flex;
    align-self: flex-end;
    align-items: center;
    margin-top: 3px;
  }

  .stars__container{

  }

  .feedback__amount{
    font-size: 10px;
    line-height: 12px;
    color: #999;
    margin: 3px 0 0 10px;
  }

  .average__rating{
    font-size: 12px;
    line-height: 16px;
    color: #333;
    margin: 3px 0 0 10px;
  }

  .item__stars{
    display: flex;
    align-items: center;
  }

</style>
