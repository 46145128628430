import axios from 'axios'

let cancelToken;

const HTTP = axios.create({
  /**
   * For local dev only!
   * COMMENT this out before making build!
   */
  // withCredentials: true,
  // baseURL: 'https://presentation.recmanbeta.local/api/',

  /**
   * UNCOMMENT this out before making build!
   */
  baseURL: '/api/'
})


const request = (data) => {
  if (typeof cancelToken !== typeof undefined) {
    cancelToken.cancel("Operation canceled due to new request.")
  }
  cancelToken = axios.CancelToken.source();

  const url = `index.php`;
  return HTTP.post(url, data, {cancelToken: cancelToken.token});
};

export {
  request,
  cancelToken
}


