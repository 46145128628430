<template>
  <div ref="content" class="popover__container" @mouseover="popoverShown = true" @mouseleave="popoverShown = false">
    <slot  name="content"></slot>
    <transition>
      <div class="popover hidden" :style="{left: offset + 'px'}" v-if="popoverShown && !popoverDisabled" ref="popover">
        <slot name="popover"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
  import { nextTick } from 'vue';

  export default {
    props: ['popoverDisabled'],
    data(){
      return{
        popoverShown: false,
        offset: null
      }
    },
    watch: {
      async popoverShown(){
        if(this.popoverShown && !this.popoverDisabled){
          await nextTick();
          const popover = this.$refs.popover;
          const contentWidth = this.$refs.content.getBoundingClientRect().width;
          const popoverWidth = popover.getBoundingClientRect().width;

          if(popoverWidth >= contentWidth){
            this.offset = (contentWidth - popoverWidth) / 2;
          }
          else{
            this.offset = (contentWidth - popoverWidth) / 2;
          }

          await nextTick();
          const popoverPosition = popover.getBoundingClientRect();

          if(popoverPosition.x < 0) {
            popover.classList.add('popover__left-alignment');
            this.offset = '-14';
          }

          popover.classList.remove('hidden');
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .popover__container{
    position: relative;
  }

  .popover{
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;
    color: #FFF;
    background-color: #494949;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    padding: 11px 16px 9px 16px;
    position: absolute;
    top: -45px;
    white-space: nowrap;

    &:after{
      top: 100%;
      left: 50%;
      /*border: solid transparent;*/
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-style: solid;
      border-color: rgba(136, 183, 213, 0);
      border-top-color: #494949;
      border-width: 8px;
      margin-left: -8px;
    }

    &.hidden{
      opacity: 0;
    }
  }

  .popover__left-alignment{
    &:after{
      left: 20px;
    }
  }

  .v-enter-from,
  .v-leave-to{
    opacity: 0;
  }
  .v-enter-to{
    opacity: 1;
  }
  .v-enter-active,
  .v-leave-active{
    transition: opacity .2s ease-in-out;
  }

</style>
