<template>
  <div class="preview">
    Preview mode
  </div>
</template>

<script>
  export default {

  }
</script>

<style scoped lang="scss">
  .preview{
    background: rgba(0, 0, 0, .8);
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    color: #FFF;
    padding: 13px 20px;
    top: 0;
    position: sticky;
    z-index: 100;
  }
</style>