import { createRouter, createWebHistory } from 'vue-router';
import OverviewWrap from '../views/OverviewWrap.vue';
import store from '../store';

const Candidate = () => import('../views/Candidate.vue');
const CandidateSingle = () => import('../views/CandidateSingle.vue');
const PresentationClosed = () => import('../views/PresentationClosed.vue');
const Recaptcha = () => import('../views/Auth/Recaptcha.vue');
const Auth = () => import('../views/Auth/Auth.vue');
const Error404 = () => import('../views/404.vue');
const LoggedOut = () => import('../views/LoggedOut.vue');

const routes = [
  {
    path: '/',
    name: 'OverviewWrap',
    component: OverviewWrap
  },
  {
    path: '/candidate',
    name: 'Candidate',
    component: Candidate
  },
  {
    path: '/candidate-single',
    name: 'CandidateSingle',
    component: CandidateSingle
  },
  {
    path: '/presentation-closed',
    name: 'PresentationClosed',
    component: PresentationClosed
  },
  {
    path: '/404',
    name: 'Error',
    component: Error404
  },
  {
    path: '/recaptcha',
    name: 'Recaptcha',
    component: Recaptcha
  },
  {
    path: '/auth',
    name: 'Auth',
    component: Auth
  },
  {
    path: '/logged-out',
    name: 'LoggedOut',
    component: LoggedOut
  },

];



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if((to.name === 'Recaptcha' || to.name === 'Auth' || to.name === 'PresentationClosed') && from.name === undefined){
    next({name: 'OverviewWrap', query: {key: store.state.key}});
  }
  else{
    next();
  }

})

export default router
