import { createStore } from 'vuex'
import { request } from '@/api/APIService';

export default createStore({
  state(){
    return{
      initialData: null,
      presentationData: null,
      presentationId: null,
      key: null,
      singleCandidate: false,
      receiver: null,
      lang: null,
      ssoIds: null,
      reviewAccess: 1,
      hasAuth: null
    }
  },
  mutations: {
    setInitialData(state, payload){
      this.state.initialData = payload;
    },
    setPresentationData(state, payload){
      this.state.presentationData = payload;
    },
    setKey(state, payload){
      state.key = payload;
    },
    setIsSingleCandidate(state, payload){
      payload > 1 ? state.singleCandidate = false : state.singleCandidate = true;
    },
    setReceiver(state, payload){
      state.receiver = payload;
    },
    setLang(state, payload){
      state.lang = payload;
    },
    setSsoIds(state, payload){
      state.ssoIds = payload;
    },
    setPresentationId(state, payload){
      state.presentationId = payload;
    },
    setReviewAccess(state, paylpad){
      state.reviewAccess = paylpad;
    },
    setAuth(state, payload){
      state.hasAuth = payload;
    },
    candidatesSort(state){
      // state.initialData.candidates.forEach((el, i) => {
      //   if(el.name === ''){
      //     el.name = "Candidate " + (i + 1);
      //   }
      // });
      state.initialData.candidates.sort((a, b) => {

        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }

        return 0;
      });
    }
  },
  actions: {
    addKey( { commit } ){
      let currentUrl = window.location;
      let url = new URL(currentUrl);
      let key = url.searchParams.get("key");
      commit('setKey', key);
    },
    candidatesSort( { commit } ){
      commit('candidatesSort');
    },
    async fetchInitialData( {commit, state} ){
      const data = {
        key: state.key,
        scope: 'fetchOverview'
      };

      try {
        let res = await request(data);

        if (res.data.success){
          mapOtherReceiversReviewToCandidates(res.data.data.candidates, res.data.data.otherReceivers)
          commit('setInitialData', res.data.data);
        }
      }
      catch (e) {
        console.log(e)
      }
    },
  },
});

function mapOtherReceiversReviewToCandidates(candidates, otherReceivers) {
  otherReceivers.forEach(receiver => {
    if(receiver.review.length > 0) {
      receiver.review.forEach(review => {
        candidates.some(candidate => {
          if(candidate.candidateId === review.candidateId){
            candidate.reviews.push({comment: review.comment, created: review.created, rating: review.rating, name: receiver.name, fromReceiver: true})
          }
        });
      })
    }
  });
}
