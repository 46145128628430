<template>
  <div class="form__section-field" :class='{read : mode === "read"}' @mouseleave="mouseLeaveHandler">
    <i class="fa-star far"
       :class="{
         fas: item <= currentChoose || item - 0.5 == rating || item - currentChoose === 0.5,
         fal: item > currentChoose && item - 0.5 != rating,
         star__error: isRequiredShown,
         'fa-star-half': item - currentChoose === 0.5 || item - 0.5 == rating,
         small: small
       }"
       v-for="(item, index) in 5"
       :key="index"
       @mouseenter="mouseEnterHandler(item)"
       @click="clickHandler(item)"
    >
    </i>
  </div>
  <span class="validator" v-if="isRequiredShown">{{ t('Please rate the candidate.') }}</span>
</template>


<script>
  import { useI18n } from "vue-i18n";

  export default {
    props: ["small", "mode", "rating", "reset"],
    setup(){
      const { t } = useI18n();
      return { t };
    },
    data(){
      return{
        currentChoose: 0,
        clicked: false,
        selected: false,
        data: null,
        isRequiredShown: false,
        title: null,
        ratingInteger: Math.round(+this.rating)
      }
    },
    created(){
      this.rating ? this.currentChoose = this.rating : null;
      const fractionalPart = (this.currentChoose - Math.floor(this.currentChoose)).toFixed(1) * 10;
      let result;

      switch (fractionalPart) {
        case 0: case 1: case 2:
          result = 0;
          break;
        case 3: case 4: case 5: case 6: case 7:
          result = 0.5;
          break;
        case 8: case 9:
          result = 1;
      }

      this.currentChoose = Math.trunc(this.currentChoose) + result;
    },
    watch: {
      rating(){
        this.rating ? this.currentChoose = this.rating : null;
        this.ratingInteger = Math.round(+this.rating);
        this.currentChoose = this.ratingInteger;
      },
      reset(){
        if(this.mode !== 'reading'){
          this.currentChoose = null;
        }

      }
    },
    methods: {
      validation(){
        if(!this.selected){
          this.isRequiredShown = true;
          return false;
        }
        else{
          this.isRequiredShown = false;
          return this.selected;
        }
      },
      mouseEnterHandler(item){
        if(this.mode === 'read'){
          return
        }

        this.clicked = false;
        this.currentChoose = item;
      },
      mouseLeaveHandler(){
        if(this.mode === 'read'){
          return
        }

        if(!this.clicked){
          this.currentChoose = 0;
        }
        if(this.selected){
          this.currentChoose = this.selected;
        }
      },
      clickHandler(item){
        if(this.mode === 'read'){
          return
        }

        if(this.selected === item){
          this.currentChoose = 0;
          this.selected = 0;
        }
        else{
          this.currentChoose = item;
          this.selected = item;
          this.isRequiredShown = false;
        }
        this.clicked = true;
      }
    }
  }
</script>


<style lang="scss" scoped>
  .form__section-field.read{
    .fa-star{
      cursor: default;
    }
  }

  .form__section-title{
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 10px;
  }

  .form__section-required{
    margin-left: 5px;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: #ED2828;
  }

  .fa-star{
    cursor: pointer;
    padding: 0 3px;
    width: 18px;
    height: 16px;
    color: #999;
    font-size: 14px;

    &.small{
      padding: 0 2px;
      width: 13px;

      &:last-child{
        padding-right: 0;
      }
    }

    &:first-child{
      padding-left: 0;
    }

    &.fas__nocursor{
      cursor: initial;
    }
  }

  .fas.fa-star{
    color: #FFAE00;

    &:before{
      font-size: 14px;
    }

    &.small{
      &:before{
        font-size: 11px;
      }
    }
  }

  .fa-star.fal{
    &:before{
      font-size: 14px;
    }

    &.small{
      &:before{
        font-size: 11px;
      }
    }
  }

  .star__error{
    color: #ED2828;
  }

  .star__hidden{
    visibility: hidden;
  }

  .validator{
    font-weight: 600;
    font-size: 9px;
    line-height: 11px;
    color: #ED2828;
    margin-top: 3px;
  }
</style>
